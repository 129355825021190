import React, { useContext, useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { isBefore, subDays } from 'date-fns';
import Link from 'components/Link';

import { useIntl } from 'context/IntlContext';
import LocationContext from 'context/LocationContext';
import ViewerContext from 'context/ViewerContext';

import { replaceAll } from 'utils';

import playStoreIcon from 'images/icons/app-stores/android.svg';
import appStoreIcon from 'images/icons/app-stores/ios.svg';

const defaultValue = {
  login: '',
  signup: '',
  help: '',
  privacy: '',
  terms: '',
  cookies: '',
  apps: {
    iOS: '',
    android: '',
  },
  affiliatesTerms: {
    registrationForm: '',
    terms: '',
  },
};

/**
 * Provides links with the right language prefixes and profile type
 */
const LinksContext = React.createContext(defaultValue);

const linksQuery = graphql`
  query LinksQuery {
    site {
      siteMetadata {
        links {
          login {
            personal {
              lang
              link
            }
            business {
              lang
              link
            }
          }
          signup {
            personal {
              lang
              link
            }
            business {
              lang
              link
            }
          }
          help {
            lang
            link
          }
          affiliates {
            terms
            registrationForm
            registrationWithAffiliate
          }
        }
      }
    }
    
  }
`;

const TARGET_CIRCLE_ID = 'click_id';
const TARGET_CIRCLE_DATE = 'click_id_date';

const getTargetCircleId = () => localStorage.getItem(TARGET_CIRCLE_ID);
const setTargetCircleId = (date) =>
  localStorage.setItem(TARGET_CIRCLE_ID, date);

const getTargetCircleDate = () => localStorage.getItem(TARGET_CIRCLE_DATE);
const setTargetCircleDate = (date) =>
  localStorage.setItem(TARGET_CIRCLE_DATE, date);

const removeTargetCircleInfo = () => {
  localStorage.removeItem(TARGET_CIRCLE_ID);
  localStorage.removeItem(TARGET_CIRCLE_DATE);
};

export const LinksProvider = ({ profileType = 'personal', ...props }) => {
  const data = useStaticQuery(linksQuery);
  const { locale } = useIntl();
  const { search } = useContext(LocationContext);
  const { countryCode } = useContext(ViewerContext);
  const [clickId, setClickId] = useState(null);

  useEffect(() => {
    const storedId = getTargetCircleId();
    const paramId = search.click_id;

    // Check param, then localStorage, then state
    const id = paramId || storedId || clickId;

    // if there's no click_id coming from anywhere, stop
    if (!id) return;

    const date = getTargetCircleDate();

    // If the query param exists, record the ID and time
    if (paramId) {
      setTargetCircleDate(new Date().toUTCString());
      setTargetCircleId(paramId);
      setClickId(paramId);
      return;
    }

    // If no param exists and this is a repeat visit happening after 30 days
    if (date && isBefore(date, subDays(Date.now(), 30))) {
      removeTargetCircleInfo();
      return;
    }

    // If the ID comes from localStorage or it is (somehow) a different
    // click_id, set it in state
    if (clickId !== id) setClickId(id);
  }, [clickId, search]);

  const { links } = data.site.siteMetadata;
  const langWithUnderscore = locale.replace('-', '_');

  const signup = clickId
    ? replaceAll(links.affiliates.registrationWithAffiliate, {
        affiliateToken: clickId,
        langQueryParam: locale,
        profileTypeQueryParam: profileType,
      })
    : links.signup[profileType].find((l) => l.lang === langWithUnderscore);
  // const {
  //   graphCMS: { pages },
  // } = data;

  // const doesSelectedCountryHaveOwnTermsPage =
  //   pages.filter((p) => p.country === countryCode && p.name === 'terms')
  //     .length > 0;

  // const termsLink =
  //   doesSelectedCountryHaveOwnTermsPage && locale === 'en'
  //     ? `/${locale}/terms/${countryCode}`
  //     : `/${locale}/terms`;

  // const doesSelectedCountryHaveOwnPrivacyPage =
  //   pages.filter(
  //     (p) => p.country === countryCode && p.name === 'privacy-policy-cookies',
  //   ).length > 0;
  // const privacyAndCookiesLink =
  //   doesSelectedCountryHaveOwnPrivacyPage && locale === 'en'
  //     ? `/${locale}/privacy-policy-cookies/${countryCode}`
  //     : `/${locale}/privacy-policy-cookies`;

  const loginLink = `${
    links.login[profileType].find((l) => l.lang === langWithUnderscore)?.link
  }&country=${countryCode}`;

  const value = {
    login: loginLink,
    signup,
    help: links.help[langWithUnderscore],
    // privacy: privacyAndCookiesLink,
    // terms: termsLink,
    // cookies: privacyAndCookiesLink
  };

  return <LinksContext.Provider {...props} value={value} />;
};

export const useLinks = () => useContext(LinksContext);

// export const useAppLinks = () => {
//   const PlayStore = playStoreIcon;
//   const AppStore = appStoreIcon;
//   const links = useLinks();

//   return {
//     PlayStoreLink: (props) => (
//       <Link href={links.apps.android} title="Google Play Store">
//         {/*
//          * The viewBox prop is to remove the blank space that surrounds the logo; see:
//          * https://github.com/steverichey/google-play-badge-svg/issues/2#issuecomment-341644889
//          */}
//         <PlayStore height="40px" viewBox="10 10 135 40" {...props} />
//       </Link>
//     ),
//     AppStoreLink: (props) => (
//       <Link href={links.apps.iOS} title="App Store">
//         <AppStore height="40px" {...props} />
//       </Link>
//     ),
//   };
// };

export default LinksContext;
