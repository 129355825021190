/** @jsx jsx */
import { Container, jsx } from 'theme-ui';
import { FC } from 'react';

import { useIntl } from '../../context/IntlContext';

import { Box, Button, Flex } from '..';
import Link from '../Link';
import Logo from '../Logo';

import MobileMenu from './MobileMenu';
import NavigationMenu from './NavigationMenu';
import LanguageSelector from '../LanguageSelector';
import { color } from 'src/theme/types'

export const Header: FC = () => {
  const { t } = useIntl();

  return (
    <Box
      as="header"
      bg="navigationPrimaryBackground"
      sx={{
        paddingY: 2,
        position: 'sticky',
        top: 0,
        zIndex: 9,
      }}
    >
      <Container>
        <Flex>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Link to="/">
              <Logo
                sx={{
                  color: 'navigationPrimaryText',
                }}
                position='header'
              />
            </Link>
          </Box>
          <Box
            sx={{
              display: ['none', 'none', 'flex'],
              justifyContent: 'space-between',
            }}
          >
            <NavigationMenu />
          </Box>
          <Box
            sx={{
              display: ['none', 'none', 'flex'],
              alignItems: 'center',
              justifyContent: 'flex-end',
              ml: 'auto',
            }}
          >
            <Flex sx={{ display: 'none' }}>
              <Link
                sx={{
                  textDecoration: 'none',
                  color: 'primary',
                  appearance: 'none',
                  textAlign: 'center',
                  px: 6,
                  py: 3,
                  fontWeight: 600,
                }}
                to={t('global.buttons.login.link')}
              >
                {t('global.buttons.login')}
              </Link>
              <Button
                as={Link}
                variant="primary"
                to={t('global.buttons.signup.link')}
              >
                {t('global.buttons.signup')}
              </Button>
            </Flex>
          </Box>
          <LanguageSelector />
          <MobileMenu>
            {/* <Flex>
              <Link variant="button" to="https://app.ibanwallet.com">
                {t('global.buttons.login')}
              </Link>
              <Button
                as={Link}
                variant="primary"
                to="https://app.ibanwallet.com"
              >
                {t('global.buttons.signup')}
              </Button>
            </Flex> */}
            <NavigationMenu variant="vertical" />
          </MobileMenu>
        </Flex>
      </Container>
    </Box>
  );
};
