import React from 'react';
import nest from 'recompose/nest';

import { CurrencyProvider } from 'context/CurrencyContext';
import { I18nContextProvider } from 'context/I18nContext';
import { SavingsProductsProvider } from 'context/SavingProductsContext';
import { ViewerProvider } from 'context/ViewerContext';

import { LocationProvider } from 'context/LocationContext';
import FlagProvider from '@unleash/proxy-client-react';

// const config = {
//   url: process.env.GATSBY_UNLEASH_URL,
//   clientKey: process.env.GATSBY_UNLEASH_CLIENT_KEY,
//   refreshInterval: 15,
//   appName: process.env.GATSBY_UNLEASH_APP_NAME,
//   environment: process.env.GATSBY_UNLEASH_ENVIRONMENT,
// };

const wrapRootElement = ({ element }) => {
  const AllRootProviders = nest(
    I18nContextProvider,
    LocationProvider,
    ViewerProvider,
    CurrencyProvider,
    SavingsProductsProvider,
  );

  return (
    <AllRootProviders locale="es" defaultLocale="es">
      <FlagProvider>{element}</FlagProvider>
    </AllRootProviders>
  );
};

export default wrapRootElement;
