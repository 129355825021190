import React from 'react';
import NeowintechLogo from './icons/neowintech.svg';
import IbanLogo from './icons/iban.svg';
import SafeBrokLogo from './icons/SafeBrok-MKP.svg';
import TheSafeCompany from './icons/SVG-THESAFECOMPANY.svg';
import TheSafeCompanyWhite from './icons/THESAFECOMPANY-BCO.svg';
import TheSafeCompanyFooter from './icons/SVG-THESAFECOMPANY-FOOTER.svg';
import TheSafeCompanyVertical from './icons/tsc-log-vertical.svg';
import TheSafeCompanyHorizontal from './icons/tsc-logo-horizontal.svg';
import TheSafeCompanyHorizontalWhite from './icons/tsc-logo-horizontal-white.svg';

const getBrand = (applicationID: string) => {
  switch (applicationID) {
    case 'groot':
      return 'iban';
    case 'safebrok':
      return 'safebrok';
    case 'neowintech':
      return 'neowintech';
    default:
      return 'the-safe-company';
  }
}

const currentBrand: Brand = getBrand(process.env.APPLICATION_ID || 'the-safe-company');

const getTheSafeCompanyLogo: any = ({position, ...props}: any) => {
  switch (position) {
    case 'footer':
      return <TheSafeCompanyHorizontal {...props} />;
    case 'header':
      return <TheSafeCompanyHorizontalWhite {...props} />;
    default :
      return <TheSafeCompany {...props} />;
  }
} 

const Logo = ({ ...props }: any) => {
  switch (currentBrand) {
    case 'neowintech':
      return <NeowintechLogo {...props} />;
    case 'iban':
      return <IbanLogo {...props} />;
    case 'safebrok':
      return <SafeBrokLogo {...props} />;
    case 'the-safe-company':
      return getTheSafeCompanyLogo(props);
    default:
      return null;
  }
};

export default Logo;
