/** @jsx jsx */
import { Button, Container, Box, Flex, Divider, jsx, Text } from 'theme-ui';
import { FC, useContext } from 'react';

import { NavMenu, MenuItem } from 'gatsby-theme-jane/Navigation';
import { useNavigation } from '../../context/NavigationContext';
import { useIntl } from '../../context/IntlContext';
import ViewerContext from '../../context/ViewerContext';

import Link from '../Link';
import Logo from '../Logo';
import LanguageSelector from '../LanguageSelector';

interface MenuProps {
  item: MenuItem | NavMenu;
  before?: JSX.Element;
  after?: JSX.Element;
}

const Menu: FC<MenuProps> = ({ item, before, after, ...props }) => {
  const { t, locale } = useIntl();
  const { countryCode } = useContext(ViewerContext);
  const { menu } = item;
  const union = menu.type === 'tab' ? '#' : '/';
  const path = item?.to ? `/${item.to}` : '';

  const shouldShow = (
    item: MenuItem,
    locale: string,
    countryCode: string,
  ): boolean => {
    if (!!item.hidden) return false;
    if (item.onlyCountries && item.onlyCountries?.length > 0) {
      if (item.onlyCountries?.includes(countryCode)) return true;
      return false;
    }

    if (!item.countryRestriction && !item.localeRestriction) return true;

    if (item.countryRestriction?.includes(countryCode)) return false;
    if (item.localeRestriction?.includes(locale)) return false;

    return true;
  };

  return (
    <ul {...props}>
      {before}
      {menu.items.map((i: MenuItem) => {
        if (shouldShow(i, locale, countryCode))
          return (
            <li key={i.label}>
              {i.menu ? (
                t(i.label)
              ) : (
                <Link key={i.label} to={i.external || `${path}${union}${i.to}`}>
                  {t(i.label)}
                </Link>
              )}
              {i.menu && <Menu item={i} />}
            </li>
          );
      })}
      {after}
    </ul>
  );
};

export const Footer: FC = ({ children }) => {
  const { t } = useIntl();
  const { selectMenu } = useNavigation();
  const menu = selectMenu('footer');

  return (
    <Box
      as="footer"
      bg="primaryBackground"
      sx={{
        borderTop: '1px solid',
        borderColor: 'senary',
        paddingY: 11,
      }}
    >
      <Container>
        <Flex>
          <Box
            as="nav"
            sx={{
              width: '100%',
            }}
          >
            <Menu
              item={menu}
              before={
                <li>
                  <Link to="/">
                    <Logo
                      sx={{
                        maxWidth: '8rem',
                        color: 'quaternary',
                      }}
                      position='footer'
                    />
                  </Link>
                  <LanguageSelector fixed />
                </li>
              }
              sx={{
                display: 'flex',
                flexDirection: ['column', 'column', 'row'],
                // justifyContent: 'space-between',
                listStyle: 'none',
                p: 0,
                m: 0,
                '& > li': {
                  p: 0,
                  mr: 12,
                  color: '#9599a6',
                },
                '& > li > a': {
                  color: 'septenary',
                },
                '& > li > ul > li': {
                  py: 2,
                  '&:hover': {
                    a: {
                      color: 'primary',
                    },
                  },
                },
                '& > li > ul > li > a': {
                  color: 'quinary',
                },
                '& ul': {
                  listStyle: 'none',
                  m: 0,
                  py: 6,
                  px: 0,
                },
              }}
            />
          </Box>
        </Flex>
        <Divider />
        <Flex
          sx={{
            flexDirection: ['column', 'column', 'row'],
            bg: 'primaryBackground',
          }}
        >
          <Box>
            <Flex>
              <Link to="/privacy-policy" sx={{ color: 'primary', mr: 2 }}>
                {t('footer.privacy_policy')}
              </Link>
              <Link to="/terms-and-conditions" sx={{ color: 'primary' }}>
                {t('footer.terms')}
              </Link>
            </Flex>
            <Text variant="smallReferences" sx={{ color: '#9599a6' }}>
              {t('footer.trademark')}
            </Text>
          </Box>
          <Box
            sx={{
              // display: ['flex', 'flex', 'none'],
              display: 'none',
              flexDirection: ['row', 'row', 'initial'],
              py: [6, 6, 0],
              justifyContent: 'center',
            }}
          >
            <Button
              as={Link}
              variant="dark"
              mr={2}
              to={t('homepage.home.cta.mobile.ios.link')}
            >
              <svg
                sx={{ mr: 3 }}
                aria-hidden="true"
                focusable="false"
                width="24px"
                height="24px"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M3 17a1 1 0 0 1 0-2h10.5c1 0 2 2 1.5 2H3zm14 0a1 1 0 0 1 0-2h4a1 1 0 0 1 0 2h-4zM12.633 3.501a1 1 0 0 1 1.734.998L7.46 16.495a1 1 0 0 1-1.734-.997L12.633 3.5zM4 18.5c.5-1 3.5-2 2.5-.28A852.88 852.88 0 0 1 4.867 21a1 1 0 0 1-1.734-.998L4 18.5zM9.133 4.499a1 1 0 1 1 1.734-.998L12.61 6.53a1 1 0 1 1-1.733.998L9.133 4.499zM13 11.5c-.898-1.5 0-4.5.716-3.004L20.366 20a1 1 0 0 1-1.733.998L13 11.5z"
                />
                <rect
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                  fill="rgba(0, 0, 0, 0)"
                />
              </svg>
              {t('homepage.home.cta.mobile.ios')}
            </Button>
            <Button
              as={Link}
              to={t('homepage.home.cta.mobile.android.link')}
              variant="dark"
            >
              <svg
                sx={{ mr: 3 }}
                aria-hidden="true"
                focusable="false"
                width="24px"
                height="24px"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <path
                  d="M12.954 11.616l2.957-2.957L6.36 3.291c-.633-.342-1.226-.39-1.746-.016l8.34 8.341zm3.461 3.462l3.074-1.729c.6-.336.929-.812.929-1.34c0-.527-.329-1.004-.928-1.34l-2.783-1.563l-3.133 3.132l2.841 2.84zM4.1 4.002c-.064.197-.1.417-.1.658v14.705c0 .381.084.709.236.97l8.097-8.098L4.1 4.002zm8.854 8.855L4.902 20.91c.154.059.32.09.495.09c.312 0 .637-.092.968-.276l9.255-5.197l-2.666-2.67z"
                  fill="currentColor"
                />
                <rect
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                  fill="rgba(0, 0, 0, 0)"
                />
              </svg>
              {t('homepage.home.cta.mobile.android')}
            </Button>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default Footer;
