import React, { useCallback, useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import * as Navigation from 'gatsby-theme-jane/Navigation';

import '../../fragments';

const query = graphql`
  query NavigationQuery {
    site {
      siteMetadata {
        navigation {
          id
          menu {
            ...MenuLevel0
            items {
              id
              label
              to
              hidden
              menu {
                ...MenuLevel1
              }
            }
          }
        }
      }
    }
  }
`;

const NavigationContext = React.createContext<
  Navigation.NavigationContext | undefined
>(undefined);

export const NavigationProvider: React.FC<{ currentPageName: string}> = ({ currentPageName, ...props }) => {
  const data = useStaticQuery(query);

  const {
    navigation,
  }: { navigation: Navigation.NavMenu[] } = data.site.siteMetadata;

  const selectMenu = useCallback(
    (id: Navigation.MenuItem['id']) => {
      const lookup = navigation.find((menu) => menu.id === id);
      if (lookup === undefined) {
        throw new TypeError(`Navigation menu with id "${id}" not found!`);
      }
      return lookup;
    },
    [navigation],
  );

  return (
    <NavigationContext.Provider
      {...props}
      value={{ navigation, selectMenu, currentPageName }}
    />
  );
};

export const useNavigation = (): Navigation.NavigationContext => {
  const context = useContext(NavigationContext);
  if (context === undefined) {
    throw new TypeError(
      'useNavigation must be used within a NavigationProvider',
    );
  }
  return context;
};

export default NavigationContext;
